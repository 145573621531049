import React, { useState } from 'react';
import { Radio, RadioGroup } from '@headlessui/react'
import { useNavigate } from "react-router-dom";

const pushOptions = [
    { requestedBy: 'Room A', requestedFor: 'Whiteboard', isEnabled: true },
    { requestedBy: 'Room A', requestedFor: 'Projector', isEnabled: true },
    { requestedBy: 'Room A', requestedFor: 'TECHSupport', isEnabled: true },
    { requestedBy: 'Room A', requestedFor: 'Juice', isEnabled: true },
    { requestedBy: 'Room A', requestedFor: 'Coffee', isEnabled: true },
    { requestedBy: 'Room A', requestedFor: 'Tea', isEnabled: true },
]

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

export default function Push() {
    const navigate = useNavigate()
    const [pushOption, setPushOption] = useState(null)

    const notify = async (pOption) => {
        setPushOption(pOption);
        fetch(`${process.env.REACT_APP_VITE_SERVER}/notify`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({
                ...pOption,
                sendAt: new Date()
            })
        })
            .then(res => res.json())
            .then(function (data) {
                return data;
            });
    }

    return (
        <div className="bg-gray-500 h-screen flex justify-center items-center w-screen px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl p-4">

                <fieldset aria-label="Choose a memory option">
                    <div className="flex items-center justify-between">
                        <div className="text-xl font-medium leading-6 text-white">Push Demo - Room A</div>
                    </div>

                    <RadioGroup value={pushOption} onChange={notify} className="mt-5 grid grid-cols-3 gap-4 sm:grid-cols-6">
                        {pushOptions.map((option, idx) => (
                            <Radio
                                key={idx}
                                value={option}
                                disabled={!option.isEnabled}
                                className={classNames(
                                    option.isEnabled ? 'cursor-pointer focus:outline-none' : 'cursor-not-allowed opacity-25',
                                    'bg-teal-300 h-[60px] text-center flex items-center justify-center rounded-md bg-white px-3 py-3 text-sm uppercase font-semibold text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset',
                                )}
                            >
                                <div>{option.requestedBy} <small className="text-xs font-normal">{option.requestedFor}</small></div>
                            </Radio>
                        ))}
                    </RadioGroup>
                </fieldset>
            </div>
        </div>
    )
}