import React from 'react';
import logo from './logo.svg';
import { NotificationBtn } from "./components/Notification";

function App() {
  return (
    <div className="bg-gray-500 h-screen flex justify-center items-center w-screen px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl p-4">
        <NotificationBtn />
      </div>
    </div>
  );
}

export default App;
