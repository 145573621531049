class WebPushService {
    static hasPermission() {
        return Notification.permission === "granted";
    }

    static async requestPermission() {
        return await Notification.requestPermission();
    }

    static async getSubscription() {
        return await navigator.serviceWorker.ready.then(async (registration) => {
            return await registration.pushManager.getSubscription();
        });
    }

    static async subscribe() {
        if (!'serviceWorker' in navigator) {
            console.error('service worker cannot be found');
            return;
        }
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
        });
        return subscription;
    }

    static async unsubscribe() {
        const subscription = await this.getSubscription();
        if (subscription) {
            await subscription.unsubscribe();
        }
        return subscription;
    }

    static async subscribeTo(subscription) {
        let sub = JSON.parse(JSON.stringify(subscription));
        let token = sub.keys.p256dh;
        let auth = sub.keys.auth;

        return fetch(`${process.env.REACT_APP_VITE_SERVER}/subscribe`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({
                endpoint: sub.endpoint,
                token,
                auth
            })
        })
            .then(res => res.json())
            .then(function (data) {
                return data;
            });
    }
}

export default WebPushService;