import WebPushService from "../services/web-push";
import { useState } from 'react'
import { Description, Field, Label, Switch } from '@headlessui/react'
import { useNavigate } from "react-router-dom";

export const NotificationBtn = () => {
    const navigate = useNavigate()
    const [isSubscribed, setIsSubscribed] = useState(false);
    const handleChange = async (value) => {
        setIsSubscribed(value);
        
        try {
            // if (!isSubscribed) {
            //     const payload = await WebPushService.unsubscribe();
            //     if (payload) {
            //         // TODO: unsubscribe is not supported yet
            //         // unsubscribeFromWebPush(payload); // server
            //     }
            //     return;
            // }
            if (!WebPushService.hasPermission()) {
                await WebPushService.requestPermission();
            }

            const subscription = await WebPushService.subscribe();
            await WebPushService.subscribeTo(subscription);

        } catch (error) {
            setIsSubscribed(!value);
            console.error(error);
        }
    };

    return (
        <div>
            <p className="text-xl text-white">Push Message Demo</p>
            {/* <input type="checkbox" checked={push} onChange={handlePushChange} /> */}
            <Field className="flex-col items-center justify-between">
                <span className="flex flex-grow flex-col">
                    <Label as="span" passive className="text-sm font-medium leading-6 text-gray-300">
                        Toggle below to subscribe to message push
                    </Label>
                    <Description as="span" className="mt-4 text-teal-200 underline underline-offset-4 text-sm">
                        {isSubscribed ? 'Subscribed': 'Not subscribed yet'}
                    </Description>
                </span>
                <Switch
                    checked={isSubscribed}
                    onChange={(value) => handleChange(value)}
                    className="mt-10 group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
                >
                    <span
                        aria-hidden="true"
                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                    />
                </Switch>
            </Field>
            <button className="m-4 absolute bottom-0 left-0 text-sm mt-10 bg-teal-400 px-4 py-2 rounded-sm" onClick={() => navigate('/push')}>Push Test</button>
        </div>
    );
};